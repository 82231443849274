import { template as template_4a4d65d3cf78465f9f493f4883ce4a83 } from "@ember/template-compiler";
const FKLabel = template_4a4d65d3cf78465f9f493f4883ce4a83(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
